import Landing from './views/Landing';
import './scss/App.scss';
import './scss/config/_config.scss';

function App() {
  return (
    <div className="App space-y-10">
      <Landing />
    </div>
  );
}

export default App;
