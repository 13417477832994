import { useState, useEffect } from 'react';

let debounce = (fn, ms) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, ms);
  };
};

export const useWindowResize = () => {

  const [winSize, setWinSize] = useState({ height: null, width: null, });
  const [breakpoint, setBreakpoint] = useState({ sm: null, md: null, lg: null, xl: null, xxl: null })

  const breakpoints = () => {
    setWinSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
    setBreakpoint({
      sm: window.innerWidth > 0 && window.innerWidth < 640,
      md: window.innerWidth > 640 && window.innerWidth < 768,
      lg: window.innerWidth > 768 && window.innerWidth < 1024,
      xl: window.innerWidth > 1024 && window.innerWidth < 1280,
      xxl: window.innerWidth > 1280 && window.innerWidth < 1536,
    })
  }
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      breakpoints();
    }, 200);

    breakpoints();

    window?.addEventListener('resize', debouncedHandleResize);
    return () => {
      window?.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return [breakpoint, winSize.width, winSize.height];
};
