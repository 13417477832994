import { createContext, useContext, useReducer } from 'react';

const MyContext = createContext();

const initialState = {
    favoriteImages: [],
    animePlay: true
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DOWNLOAD_IMAGE':
            const { payload: downloadImage } = action;

            return {
                ...state,
                downloadImage: downloadImage,
            };

        case 'SET_FAVORITE':
            const { payload: addAllToFavourite } = action;

            return {
                ...state,
                favoriteImages: addAllToFavourite,
            };

        case 'TOGGLE_FAVORITE':
            const { payload: toggledImage } = action;

            const isFavorite = state.favoriteImages.some(
                (favoriteImage) => favoriteImage === toggledImage
            );

            return {
                ...state,
                favoriteImages: isFavorite
                    ? state.favoriteImages.filter(
                        (favoriteImage) => favoriteImage !== toggledImage
                    )
                    : [...state.favoriteImages, toggledImage],
            };

        case 'SET_ANIME_PLAYSTATE':
            const { payload: animePlayState } = action;

            return {
                ...state,
                animePlay: animePlayState,
            };

        default:
            return state;
    }
};


const MyProvider = ({ children }) => {
    const [appState, dispatch] = useReducer(reducer, initialState);

    return (
        <MyContext.Provider value={{ appState, dispatch }}>
            {children}
        </MyContext.Provider>
    );
};

const useMyContext = () => {
    const context = useContext(MyContext);
    if (!context) {
        throw new Error('useMyContext must be used within a MyProvider');
    }
    return context;
};

export { MyProvider, useMyContext };
