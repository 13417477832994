import React, { useEffect, useState, useMemo, useRef } from 'react';
import gsap from "gsap";
import "@lottiefiles/lottie-player";
import robotAnim from '../../images/robot_anim.json';
import './LoadingScreen.scss';

export default function LoadingScreen() {
    const [robotAnime, setRobotAnime] = useState()
    const roboRef = useRef()
    const roboContRef = useRef()

    const waitText = useMemo(() => [
        "Je cybergras wordt afgereden",
        "We uploaden er nog rap een boompje bij",
        "Secondje, we doen nog iets met quantumcomputing",
        "We recalibreren je cyberhaag",
        "Je online zwembad wordt uitgegraven",
        "We renderen de bijtjes",
    ], []);

    const [currentText, setCurrentText] = useState(waitText[0]);

    useEffect(() => {

        const interval = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * waitText.length);
            setCurrentText(waitText[randomIndex]);
        }, 4000);

        return () => clearInterval(interval);
    }, [waitText]);

    useEffect(() => {
        const robot = roboRef.current;
        const roboCont = roboContRef.current;
        const roboContDimension = roboCont.getBoundingClientRect()
        const robotDimension = robot.getBoundingClientRect()

        setRobotAnime(JSON.stringify(robotAnim))

        console.log(roboContDimension, robotDimension)
        // console.log(roboContDimension, robotDimension)

        let tl = gsap.timeline();
        tl.to(robot, {
            left: () => {
                let percentage = roboContDimension.width - 180
                return `${percentage}px`
            },
            yoyo: true,
            duration: () => {
                return Math.max(roboContDimension.width / 10, 4) / 25;
            },
            repeat: -1,
            ease: "none",
            onRepeat: () => {
                robot.classList.toggle('runLeft')
            }
        })
    }, [])

    return (
        <div className="loading-screen flex flex-col items-center w-full h-full text-center flex-grow space-y-8 p-5">
            <div className='content'>
                <div ref={roboContRef} className='robot-container'>
                    <div ref={roboRef} className='robot'>
                        {robotAnime &&
                            <lottie-player
                                autoplay
                                loop
                                mode="normal"
                                src={robotAnime}
                                style={{ width: "180px" }}
                            >
                            </lottie-player>
                        }
                    </div>
                </div>
                <h1 className='waittext'>{currentText}</h1>
            </div>
        </div>
    )
}