import React from 'react';
import ReactDOM from 'react-dom/client';
import { MyProvider } from './state/Context';
import {
  createBrowserRouter, RouterProvider, Navigate
} from "react-router-dom";

import './scss/index.scss';
import App from './App';
import Upload from './views/Upload';
import Style from './views/Style';
import Results from './views/Results';
import reportWebVitals from './reportWebVitals';
import Ms2 from './components/MarchingSQ2/ms2';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/upload",
    element: <Upload />,
  },
  {
    path: "/selecteer-je-stijl",
    element: <Style />,
  },
  {
    path: "/resultaat",
    element: <Results />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  }
]);

root.render(
  <React.StrictMode>
    <MyProvider>
      <RouterProvider router={router} />
      <Ms2 />
    </MyProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
