import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { useMyContext } from '../../state/Context';
import './Download.scss';

export default function Download({ image }) {
    const { dispatch } = useMyContext();

    const handleDownload = () => {
        dispatch({
            type: 'SET_DOWNLOAD_IMAGE',
            payload: image,
        });
    }

    return (
        <div onClick={handleDownload} className='favourite--icon icon'>
            <DownloadIcon />
        </div>
    )
}