import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from "../Button/Button";
import ImageComp from "../ImageComp/ImageComp";
import CancelIcon from '@mui/icons-material/Cancel';
import 'swiper/css';
import './LightBox.scss';


export default function LightBox({ initImage, generated, handleLightbox, image, index, handleSavePictures }) {

    const handleClick = () => {
        handleLightbox({ active: false })
    }

    const handlePictures = () => {
        handleSavePictures(image)
    }

    useEffect(() => {
        document.addEventListener('keydown', (event) => {
            if (event.keyCode === 27) {
                handleLightbox({ active: false })
            }
        })
    }, [handleLightbox])

    return (
        <div className="lightbox--component">
            <div onClick={handleClick} className="close"><CancelIcon /></div>
            <div className="lightbox--inner mx-auto">
                <div className="swiper--big">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        allowTouchMove={false}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => {
                            swiper.slideTo(index, 0)
                        }}
                    >
                        {generated && generated.map((gen, i) => (
                            <SwiperSlide>
                                <ImageComp favourite firstImage={`${process.env.REACT_APP_BASE_URL}${initImage.filePath}`} secondImage={`${process.env.REACT_APP_BASE_URL}${gen.file.filePath}`} index={i} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                {/* <div className="swiper--thumb">
                    <Swiper
                        modules={[Thumbs]}
                        spaceBetween={15}
                        watchSlidesProgress
                        slidesPerView={3.5}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={setThumbsSwiper}
                    >
                        {generated && generated.map((gen, i) => (
                            <SwiperSlide>
                                <ImageComp firstImage={`${process.env.REACT_APP_BASE_URL}${gen.file.filePath}`} index={i} />
                            </SwiperSlide>

                        ))}
                    </Swiper>
                </div> */}
                <div className="button-wrapper mt-5 ">
                    <Button handleClick={handlePictures} text="Download" type={'solid'} iconRight={'download'} />
                </div>
            </div>
        </div>
    )
}