import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header/Header"
import Button from "../components/Button/Button"
import ImageComp from "../components/ImageComp/ImageComp"
import CachedIcon from '@mui/icons-material/Cached';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowResize } from "../hooks/useWindowResize";
import { useMyContext } from "../state/Context";
import LightBox from "../components/LightBox/LightBox";
import './Results.scss';
import 'swiper/css';

// const downloadImages = (imageLinks) => {
//     imageLinks.forEach((link, index) => {
//         const image = new Image();
//         image.crossOrigin = 'Anonymous';
//         image.src = link;

//         image.onload = () => {
//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');

//             // Set canvas dimensions to the image dimensions
//             canvas.width = image.width;
//             canvas.height = image.height;

//             // Draw the image on the canvas
//             context.drawImage(image, 0, 0);

//             // Convert the canvas content to a data URL
//             const dataURL = canvas.toDataURL('image/png');

//             // Create a download link
//             const a = document.createElement('a');
//             a.href = dataURL;
//             a.download = `image_${index + 1}.png`;

//             // Append the link to the body and trigger the download
//             document.body.appendChild(a);
//             a.click();

//             // Remove the link from the body
//             document.body.removeChild(a);
//         };
//     });
// };

const downloadImages = (imageLinks) => {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    imageLinks.forEach((link, index) => {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.src = link;

        image.onload = () => {
            // Set canvas dimensions to the image dimensions
            canvas.width = image.width;
            canvas.height = image.height;

            // Draw the image on the canvas
            context.drawImage(image, 0, 0);

            // Convert the canvas content to a data URL
            const dataURL = canvas.toDataURL('image/png');

            // Set the download link attributes
            downloadLink.href = dataURL;
            downloadLink.download = `image_${index + 1}.png`;

            // Trigger the download
            downloadLink.click();
        };
    });
};

//all at once
// const downloadImages = (imageLinks) => {
//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     imageLinks.forEach(async (link, index) => {
//         const image = new Image();
//         image.crossOrigin = 'Anonymous';
//         image.src = link;

//         await new Promise(resolve => {
//             image.onload = resolve;
//         });

//         // Set canvas dimensions to the image dimensions
//         canvas.width = image.width;
//         canvas.height = image.height;

//         // Draw the image on the canvas
//         context.drawImage(image, 0, 0);

//         // Convert the canvas content to a Blob
//         canvas.toBlob(blob => {
//             // Create a download link for the Blob
//             const downloadLink = document.createElement('a');
//             downloadLink.href = URL.createObjectURL(blob);
//             downloadLink.download = `image_${index + 1}.png`;

//             // Append the link to the body and trigger the download
//             document.body.appendChild(downloadLink);
//             downloadLink.click();

//             // Remove the link from the body
//             document.body.removeChild(downloadLink);
//         }, 'image/png');
//     });
// };

export default function Results() {
    const { state } = useLocation();
    const [generated, setGenerated] = useState();
    const [postCodeError, setPostCodeError] = useState(false);
    const [postCode, setPostCode] = useState(null)
    const [breakpoint, width] = useWindowResize();
    const { appState, dispatch } = useMyContext();
    const searchWrapper = useRef()
    const navigate = useNavigate();
    const [lightBox, setLightBox] = useState({ active: false, selectedImage: null });

    useEffect(() => {
        //set all images to download as initial state
        const allCreationsUrl = generated?.map((gen) => {
            return process.env.REACT_APP_BASE_URL + gen.file.filePath
        })
        dispatch({
            type: 'SET_FAVORITE',
            payload: allCreationsUrl,
        });
    }, [generated, dispatch])

    useEffect(() => {

        if (!state) {
            navigate('/')
        } else {
            setGenerated(state.creationFiles)
        }
    }, [state, navigate])

    const savePictures = (image) => {
        downloadImages(image ? [image] : appState.favoriteImages)
    }

    useEffect(() => {
        const { downloadImage } = appState
        downloadImages([downloadImage])
    }, [appState])

    const handlePostcodeSearch = (e) => {
        // const postCodeRegEx = /^([1-9]\d{3}|[1-9]\d{2}[0-8]\d{1}|999[0-2])$/;
        const postCodeRegEx = /^(?:(?:[1-9])(?:\d{3}))$/;

        const postCode = e.target.value;

        if (postCodeRegEx.test(postCode)) {
            setPostCodeError(false)
            setPostCode(e.target.value)
        } else {
            setPostCodeError(true)
        }

        handlePostcodeSubmit(e)
    }

    const handleForward = (postcode) => {
        const url = `https://tuinaannemer.be/vind-een-tuinaannemer?address_center[geocoder][geolocation_geocoder_address]=${postcode}&field_locatie_latlong_proximity=10&address=10`
        window.open(url, '_blank');
    }

    const handlePostcodeSubmit = (event) => {
        if (!postCodeError && event && event.key === 'Enter' && postCode) {
            handleForward(postCode)
        }

        if (!postCodeError && event && event === 'submit' && postCode) {
            handleForward(postCode)
        }
    }

    const handleLightbox = (image, index) => {
        const body = document.querySelector('body');
        body.classList.add('no-scroll')
        setLightBox({ active: true, selectedImage: image, index: index })
    }

    const closeLightBox = (state) => {
        const body = document.querySelector('body');
        body.classList.remove('no-scroll')
        setLightBox(state)
    }

    const reselectStyle = () => {
        navigate('/selecteer-je-stijl', { state: { id: state.initFile.id } });
    }

    const handleInputFocus = (type) => {
        type === 'focus' ? searchWrapper.current?.classList.add('focus') : searchWrapper.current?.classList.remove('focus')
    }

    const calculateSlidesPerView = () => {
        if (width > 768) {
            return 2.2
        } else {
            return 1.2
        }
    }

    useEffect(() => {
        // console.log(appState)
    }, [width, lightBox, breakpoint, generated, state, appState])

    return (
        <>
            <div className="page-wrapper results">
                <Header />
                <div className="flex-grow items-start xl:items-center lg:gap-10 grid grid-cols-12 max-w-screen-2xl mx-auto p-0 lg:p-5 space-y-8">
                    <div className="col-span-12 p-5 order-1 block lg:hidden">
                        <h1>Pak dit mee naar <span className="highlight">je tuinaannemer</span></h1>
                    </div>
                    <div className="col-span-12 lg:space-y-20 order-3 lg:order-2 lg:col-span-5 xl:col-span-5 pb-10 p-5">
                        <div className="cta-text space-y-8">
                            <h1 className="title hidden lg:block">Pak dit mee naar <span className="highlight">je tuinaannemer</span></h1>
                            <p className="hidden lg:block">Heb je al zicht op jouw droomtuin? Neem dan nu contact op met een tuinaannemer bij jou in de buurt en maak van jouw digitale droom een realiteit.</p>
                        </div>
                        <div className="search-comp bg-green-800 p-5 py-14 space-y-5">
                            <h3>Vind een tuinaannemer in je buurt</h3>
                            <div ref={searchWrapper} className="search flex">
                                <input onBlur={() => handleInputFocus('blur')} onFocus={() => handleInputFocus('focus')} type="number" maxlength="4" onKeyDown={handlePostcodeSubmit} onChange={(e) => handlePostcodeSearch(e)} className="w-full p-3" placeholder="wat is je postcode?"></input>
                                <Button handleClick={() => handlePostcodeSubmit('submit')} type={'solid'} text="zoeken" />
                            </div>
                        </div>
                        <p className="p-5 block lg:hidden">Heb je al zicht op jouw droomtuin? Neem dan nu contact op met een tuinaannemer bij jou in de buurt en maak van jouw digitale droom een realiteit.</p>
                    </div>

                    <div className="col-span-12 order-2 pl-5 lg:p-0 lg:order-3 lg:col-span-7 xl:col-span-6 xl:col-start-8">
                        {width > 1024 ?
                            <div className="grid grid-cols-8 gap-4">
                                {generated && generated.map((gen, i) => (
                                    <div className="col-span-8 md:col-span-4 xl:col-span-4">
                                        <ImageComp download handleLightbox={handleLightbox} firstImage={`${process.env.REACT_APP_BASE_URL}${gen.file.filePath}`} index={i} />
                                    </div>
                                ))}
                                <div className="button-restart text-right col-span-8">
                                    <h6 onClick={reselectStyle} className="w-full cursor-pointer">Probeer opnieuw <CachedIcon /></h6>
                                </div>
                                {/* <div className="download-restart flex gap-3 p-5">
                                    <div className="button-download">
                                        <Button handleClick={savePictures} text="Download je favoriete foto's" type={'solid'} iconLeft={'heart'} iconRight={'arrow-down'} />
                                    </div>
                                    <div className="button-restart">
                                        <Button handleClick={reselectStyle} iconRight={"restart"} text="Probeer opnieuw" type={'outline'} />
                                    </div>
                                </div> */}
                            </div>
                            :
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={calculateSlidesPerView()}
                            >
                                {generated && generated.map((gen, i) => (
                                    <SwiperSlide>
                                        <ImageComp download handleLightbox={handleLightbox} firstImage={`${process.env.REACT_APP_BASE_URL}${gen.file.filePath}`} index={i} />
                                    </SwiperSlide>

                                ))}
                            </Swiper>
                        }
                    </div>
                </div>
            </div>
            {lightBox.active &&
                <LightBox handleLightbox={closeLightBox} initImage={state.initFile} handleSavePictures={savePictures} image={lightBox.selectedImage} index={lightBox.index} generated={generated} />
            }
        </>
    )
}